import axios from 'axios';

const createRepository = (client) => ({
  async getExpirations(payload) {
    return client.post('/api/v1/expirations', payload);
  },
});

export default (nodeEnv, appHost) => {
  const protocol = nodeEnv === 'development' ? 'http' : 'https';
  const client = axios.create({
    baseURL: `${protocol}://${appHost}`,
    timeout: 10_000,
  });

  return {
    calculatorClient: client,
    calculatorRepository: createRepository(client),
  };
};
