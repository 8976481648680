/* eslint-disable react/prop-types */
import * as React from 'react';

const UserContext = React.createContext(null);

export const UserProvider = ({ children, user }) => {
  let userRef = React.useRef(user);
  return (
    <UserContext.Provider value={userRef.current}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => {
  const user = React.useContext(UserContext);
  return user;
};
