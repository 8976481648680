import React, { useEffect, useState } from 'react';
import { useCompany } from '../hooks/company';
import { useRepository } from '../hooks/api';
import Loader from '../components/Loader';
import CartTable from '../views/cart/CartTable/CartTable';

export default function Cart() {
  const company = useCompany();
  const repository = useRepository();

  const [cart, setCart] = useState({
    cartItems: [],
    totalValue: 0,
  });
  const [isLoadingData, setIsLoadingData] = useState(true);

  const init = async () => {
    try {
      const cart = await repository.company.getCart(
        company.taxIdentificationHash
      );
      setCart(cart);
    } catch (e) {
      setCart({
        cartItems: [],
        totalValue: 0,
      });
    }

    setIsLoadingData(false);
  };

  useEffect(() => {
    init();
  }, []);

  const handleCartRefresh = async () => {
    setIsLoadingData(true);
    await init();
  };

  return isLoadingData ? (
    <Loader />
  ) : (
    <CartTable cart={cart} onCartRefresh={handleCartRefresh} />
  );
}
