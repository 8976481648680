import React from 'react';
import Tooltip from '@mui/material/Tooltip';

// eslint-disable-next-line react/prop-types
function FleetTooltip({ children, placement, title }) {
  return (
    <Tooltip
      placement={placement}
      title={
        <React.Fragment>
          <span style={{ fontSize: '0.85rem' }}>{title}</span>
        </React.Fragment>
      }
    >
      {children}
    </Tooltip>
  );
}

export default FleetTooltip;
