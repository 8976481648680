/* eslint-disable react/prop-types */
import React, { useState, useCallback } from 'react';
import List from '@mui/material/List';
import Card from '@mui/material/Card';
import Table from '@mui/material/Table';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import TableContainer from '@mui/material/TableContainer';
import { useDevice } from '../../../hooks/device';
import { useRepository } from '../../../hooks/api';
import { useCompany } from '../../../hooks/company';
import { useSnackbar } from '../../../hooks/snackbar';
import TableBox from '../../../components/TableBox';
import ViewHeader from '../../../components/ViewHeader';
import { getDateLabel } from '../../../shared/labels';
import { displayPrice } from '../../../shared/numbers';
import {
  getCellStyleForMobile,
  lastRowWithoutBottomBorder,
} from '../../../shared/styles';

export default function InvoicesTable({ invoices }) {
  const company = useCompany();
  const repository = useRepository();
  const openSnackbar = useSnackbar();
  const { isBrowser } = useDevice();
  const [filter, setFilter] = useState('');
  const [filteredInvoices, setFilteredInvoices] = useState(invoices);

  const getCellStyle = useCallback(
    (style = {}) => ({
      ...getCellStyleForMobile(isBrowser),
      ...style,
    }),
    []
  );

  const handleOnFilterChange = useCallback((e) => {
    const {
      target: { value: newFilter },
    } = e;
    setFilter(newFilter);
    if (newFilter.length) {
      setFilteredInvoices(
        invoices.filter((invoice) => {
          if (invoice.number.toLowerCase().includes(newFilter.toLowerCase())) {
            return true;
          }

          return false;
        })
      );
    } else {
      setFilteredInvoices(invoices);
    }
  }, []);

  const handleShowInvoice = async (invoiceId) => {
    try {
      const file = await repository.company.getInvoice(
        company.taxIdentificationHash,
        invoiceId
      );
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL, '_blank', 'noreferrer');
    } catch (e) {
      openSnackbar('Wystąpił błąd pobierania faktury.', 'error');
    }
  };

  const renderTableBody = () => {
    if (filteredInvoices.length) {
      return filteredInvoices.map((invoice) => (
        <TableRow key={invoice.number} sx={{ ...lastRowWithoutBottomBorder }}>
          <TableCell style={getCellStyle()}>{invoice.number}</TableCell>
          <TableCell style={getCellStyle()}>
            {getDateLabel(invoice.createdAt)}
          </TableCell>
          {isBrowser ? (
            <>
              <TableCell style={getCellStyle()}>
                {displayPrice(invoice.nettPrice)}
              </TableCell>
            </>
          ) : null}

          <TableCell style={getCellStyle()}>
            {displayPrice(invoice.grossPrice)}
          </TableCell>
          <TableCell style={getCellStyle()}>
            <Button
              size='small'
              variant='outlined'
              style={{ minWidth: 24 }}
              onClick={() => handleShowInvoice(invoice.id)}
            >
              <OpenInNewIcon />
            </Button>
          </TableCell>
        </TableRow>
      ));
    }

    return (
      <TableRow>
        <TableCell
          colSpan={isBrowser ? 5 : 4}
          style={{
            textAlign: 'center',
            fontStyle: 'italic',
          }}
        >
          Nie znaleziono faktur
        </TableCell>
      </TableRow>
    );
  };

  return (
    <List style={{ flexGrow: '1', padding: 0 }}>
      <ViewHeader>
        <Typography variant='h6' style={{ fontSize: 16 }}>
          Faktury
        </Typography>
      </ViewHeader>
      <Alert severity='info'>
        W tej sekcji znajdują się wszystkie faktury wystawione za zakupy za
        pośrednictwem naszych serwisów.
      </Alert>
      <Card variant='outlined' sx={{ mt: 1, mb: 1 }}>
        <TableBox>
          <TableContainer>
            <Table style={{ tableLayout: 'fixed' }}>
              <TableHead>
                <TableRow>
                  {isBrowser ? (
                    <>
                      <TableCell>Numer faktury</TableCell>
                      <TableCell>Data wystawienia</TableCell>
                      <TableCell>Kwota netto</TableCell>
                      <TableCell>Kwota brutto</TableCell>
                    </>
                  ) : (
                    <>
                      <TableCell>Numer</TableCell>
                      <TableCell>Data</TableCell>
                      <TableCell>Brutto</TableCell>
                    </>
                  )}
                  <TableCell>Pobierz</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>
                    <TextField
                      style={{ maxWidth: 140 }}
                      label='Filtruj'
                      variant='outlined'
                      size='small'
                      value={filter}
                      onChange={handleOnFilterChange}
                    />
                  </TableCell>
                  <TableCell />
                  <TableCell />
                  <TableCell />
                  {isBrowser ? (
                    <>
                      <TableCell />
                    </>
                  ) : null}
                </TableRow>
                {renderTableBody()}
              </TableBody>
            </Table>
          </TableContainer>
        </TableBox>
      </Card>
    </List>
  );
}
