import React, { useEffect } from 'react';
import { useCompany } from '../hooks/company';
import { useRepository } from '../hooks/api';
import { initialState, reducer } from '../contexts/vehiclesReducer';
import {
  VehiclesStateProvider,
  useVehiclesStateValue,
} from '../contexts/vehiclesStateContext';
import Loader from '../components/Loader';
import VehicleTable from '../views/vehicles/VehicleTable/VehicleTable';

export default function FleetVehicles() {
  return (
    <VehiclesStateProvider initialState={initialState} reducer={reducer}>
      <StatefulFleetVehicles />
    </VehiclesStateProvider>
  );
}

function StatefulFleetVehicles() {
  const company = useCompany();
  const repository = useRepository();

  const [state, dispatch] = useVehiclesStateValue();

  const init = async () => {
    const vehicles = await repository.company.getVehicles(
      company.taxIdentificationHash
    );
    dispatch({ type: 'SET_VEHICLES', payload: vehicles });
    dispatch({ type: 'SET_FILTERED_VEHICLES', payload: vehicles });
    dispatch({ type: 'SET_IS_LOADING_VEHICLES', payload: false });
  };

  useEffect(() => {
    init();
  }, []);

  const handleVehiclesRefresh = async () => {
    dispatch({ type: 'SET_IS_LOADING_VEHICLES', payload: true });
    await init();
  };

  return state.isLoadingVehicles ? (
    <Loader />
  ) : (
    <VehicleTable onVehiclesRefresh={handleVehiclesRefresh} />
  );
}
