import React, { useEffect, useState } from 'react';
import { useCompany } from '../hooks/company';
import { useRepository } from '../hooks/api';
import Loader from '../components/Loader';
import OrdersTable from '../views/orders/OrdersTable/OrdersTable';

export default function Orders() {
  const company = useCompany();
  const repository = useRepository();

  const [orders, setOrders] = useState([]);
  const [isLoadingData, setIsLoadingData] = useState(true);

  const init = async () => {
    const orders = await repository.company.getOrders(
      company.taxIdentificationHash
    );
    setOrders(orders);
    setIsLoadingData(false);
  };

  useEffect(() => {
    init();
  }, []);

  return isLoadingData ? <Loader /> : <OrdersTable orders={orders} />;
}
