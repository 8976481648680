/* eslint-disable react/prop-types */
import React, { useMemo } from 'react';
import PanToolIcon from '@mui/icons-material/PanTool';
import FleetTooltip from './FleetTooltip';
import { useDevice } from '../hooks/device';
import WatchSvg from '../shared/svgIcons/WatchSvg';
import { getExpirationLabel } from '../shared/labels';
import { getCellStyleForMobile } from '../shared/styles';

const styles = [
  {
    condition: ({ isRegistered }) => isRegistered === false,
    chipStyle: {
      backgroundColor: 'transparent',
    },
    textStyle: {
      color: '#EF6C00',
    },
    Chip: () => {
      const tooltip =
        'Winieta oczekuje na rejestrację - nie jest jeszcze aktywna';

      return (
        <FleetTooltip title={tooltip}>
          <div>
            <PanToolIcon />
          </div>
        </FleetTooltip>
      );
    },
  },
  {
    condition: ({ hasStarted }) => hasStarted === false,
    chipStyle: {
      backgroundColor: '#e4f2e6',
    },
    textStyle: {
      color: '#046eb9',
    },
    Chip: ({ startsIn }) => {
      const tooltip =
        startsIn === 1
          ? 'Winieta rozpocznie swoją ważność jutro'
          : `Winieta rozpocznie swoją ważność za ${startsIn} dni`;

      return (
        <FleetTooltip title={tooltip}>
          <div>
            <WatchSvg />
          </div>
        </FleetTooltip>
      );
    },
  },
  {
    condition: ({ isStillValid, endsIn }) => isStillValid && endsIn >= 7,
    chipStyle: {
      backgroundColor: '#dff1ff',
    },
    textStyle: {
      color: '#046eb9',
    },
    Chip: ({ endsIn }) => getExpirationLabel(endsIn),
  },
  {
    condition: ({ isStillValid, endsIn }) => isStillValid && endsIn < 7,
    chipStyle: {
      backgroundColor: '#FFF59D',
    },
    textStyle: {
      color: '#EF6C00',
    },
    Chip: ({ endsIn }) => getExpirationLabel(endsIn),
  },
  {
    condition: ({ isStillValid }) => !isStillValid,
    chipStyle: {
      backgroundColor: '#E8E8E8',
    },
    textStyle: {
      color: '#636363',
    },
    Chip: () => 'wygasła',
  },
];

export default function ExpirationChip({
  isRegistered,
  hasStarted,
  startsIn,
  isStillValid,
  endsIn,
}) {
  const { isBrowser } = useDevice();

  const [chipStyle, textStyle, Chip] = useMemo(() => {
    for (const { condition, chipStyle, textStyle, Chip } of styles) {
      if (condition({ isRegistered, hasStarted, isStillValid, endsIn })) {
        return [chipStyle, textStyle, Chip];
      }
    }
  }, [hasStarted, startsIn, isStillValid, endsIn]);

  const getCellStyle = (style = {}) => ({
    ...getCellStyleForMobile(isBrowser),
    ...style,
  });

  return (
    <div style={{ display: 'flex' }}>
      <div
        style={{
          ...chipStyle,
          display: 'flex',
          flexDirection: 'row',
          height: '30px',
          lineHeight: '30px',
          borderRadius: '3px',
        }}
      >
        <div
          style={{
            ...(isBrowser
              ? { paddingLeft: '10px', paddingRight: '10px' }
              : { paddingLeft: '3px', paddingRight: '3px' }),
          }}
        >
          <div
            style={{
              ...textStyle,
              fontSize: 14,
              fontWeight: 500,
              ...getCellStyle(),
            }}
          >
            <Chip
              isRegistered={isRegistered}
              hasStarted={hasStarted}
              startsIn={startsIn}
              isStillValid={isStillValid}
              endsIn={endsIn}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
