import axios from 'axios';
import { useCamelizeResponse } from '../transformations';
import createRepository from './repository';

export default (nodeEnv, appHost) => {
  const protocol = nodeEnv === 'development' ? 'http' : 'https';
  const client = axios.create({
    baseURL: `${protocol}://${appHost}`,
    timeout: 30_000,
    headers: {
      'Content-type': 'application/json',
      'Cache-Control': 'no-cache',
      'X-CSRF-Token': getCSRFToken(),
    },
  });

  useCamelizeResponse(client);

  return { companyClient: client, companyRepository: createRepository(client) };
};

function getCSRFToken() {
  const metaTag = document.querySelector('meta[name="csrf-token"]');
  const CSRFToken = metaTag ? metaTag.content : null;
  if (!CSRFToken) {
    throw new Error('CSRF token not found');
  }

  return CSRFToken;
}
