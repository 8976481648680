export const getCellStyleForMobile = (isBrowser) => ({
  ...(isBrowser
    ? {}
    : {
        fontSize: '0.8rem',
      }),
});

export const lastRowWithoutBottomBorder = {
  '&:last-child td, &:last-child th': { border: 0 },
};
