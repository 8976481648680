const defaultCountriesFilter = {
  AT: false,
  BG: false,
  CZ: false,
  RO: false,
  SK: false,
  SI: false,
  HU: false,
  CH: false,
};

export const initialState = {
  isLoadingVehicles: true,
  vehicles: [],
  registrationNumberFilter: '',
  countriesFilter: defaultCountriesFilter,
  filteredVehicles: [],
  vehicle: null,
  isAddVignetteDialogOpen: false,
  vignettes: [],
  availableVignettes: [],
  vignette: {
    countryCode: null,
    type: null,
    period: null,
    startDate: new Date(),
    grossPrice: null,
  },
  isVignetteCountryOK: true,
  isVignetteTypeOK: true,
  isVignetteStartDateOK: true,
  countries: [
    { countryCode: 'AT', label: 'Austria' },
    { countryCode: 'BG', label: 'Bułgaria' },
    { countryCode: 'CZ', label: 'Czechy' },
    { countryCode: 'RO', label: 'Rumunia' },
    { countryCode: 'SK', label: 'Słowacja' },
    { countryCode: 'SI', label: 'Słowenia' },
    { countryCode: 'CH', label: 'Szwajcaria' },
    { countryCode: 'HU', label: 'Węgry' },
  ],
  availableCountries: [],
};

export const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_IS_LOADING_VEHICLES':
      return { ...state, isLoadingVehicles: action.payload };
    case 'SET_VEHICLES':
      return { ...state, vehicles: action.payload };
    case 'SET_REGISTRATION_NUMBER_FILTER':
      return {
        ...state,
        registrationNumberFilter: action.payload,
      };
    case 'SET_COUNTRIES_FILTER': {
      const countryCode = action.payload;

      const countriesFilter = {
        ...state.countriesFilter,
        [countryCode]: !state.countriesFilter[countryCode],
      };

      return {
        ...state,
        countriesFilter,
      };
    }
    case 'SET_DEFAULT_COUNTRIES_FILTER':
      return {
        ...state,
        countriesFilter: defaultCountriesFilter,
      };
    case 'SET_FILTERED_VEHICLES': {
      const filteredVehicles = getFilteredVehicles(
        state.vehicles,
        state.registrationNumberFilter,
        state.countriesFilter
      );
      return {
        ...state,
        filteredVehicles,
      };
    }
    case 'SET_VEHICLE':
      return {
        ...state,
        vehicle: action.payload,
      };
    case 'SET_IS_ADD_VIGNETTE_DIALOG_OPEN':
      return {
        ...state,
        isAddVignetteDialogOpen: action.payload,
      };
    case 'SET_VIGNETTES':
      return {
        ...state,
        vignettes: action.payload,
      };
    case 'SET_AVAILABLE_VIGNETTES': {
      const { availableCountries } = state;
      if (availableCountries.length === 0) {
        return {
          ...state,
          availableVignettes: [],
        };
      }

      let countryCode = action.payload;
      if (!countryCode) {
        const { availableCountries } = state;
        const [firstAvailableCountry] = availableCountries;
        ({ countryCode } = firstAvailableCountry);
      }

      const { vignettes } = state;
      const countryVignettes = vignettes[countryCode];
      if (!countryVignettes || countryVignettes.length === 0) {
        return {
          ...state,
          availableVignettes: [],
        };
      }

      const {
        vehicle: { huCategory, siCategory, roCategory },
      } = state;
      let type = null;
      switch (countryCode) {
        case 'AT':
          type = 'EV2';
          break;
        case 'BG':
          type = 'V';
          break;
        case 'CZ':
          type = 'V';
          break;
        case 'SK':
          type = 'V';
          break;
        case 'HU':
          type = huCategory;
          break;
        case 'SI':
          type = siCategory;
          break;
        case 'RO':
          type = roCategory;
          break;
        case 'CH':
          type = 'V2';
          break;
        default:
          type = null;
          break;
      }
      if (!type) {
        return {
          ...state,
          availableVignettes: [],
        };
      }

      const availableVignettes = countryVignettes.filter((cV) => {
        return cV.code.startsWith(`${countryCode}-${type}`);
      });
      if (!availableVignettes || availableVignettes.length === 0) {
        return {
          ...state,
          availableVignettes: [],
        };
      }

      return {
        ...state,
        availableVignettes,
      };
    }
    case 'SET_FIRST_AVAILABLE_VIGNETTE': {
      const { availableVignettes } = state;
      if (availableVignettes.length === 0) {
        return {
          ...state,
          vignette: {
            // DOM element with id "country-select" needs countryCode to be defined!
            ...state.vignette,
            type: null,
            period: null,
            startDate: new Date(),
            grossPrice: null,
          },
        };
      }

      const [firstAvailableVignette] = state.availableVignettes;
      const [countryCode, type, period] =
        firstAvailableVignette.code.split('-');

      return {
        ...state,
        vignette: {
          ...state.vignette,
          countryCode,
          type,
          period,
        },
      };
    }
    case 'SET_VIGNETTE_PARAMS':
      return {
        ...state,
        vignette: {
          ...state.vignette,
          ...action.payload,
        },
      };
    case 'SET_IS_VIGNETTE_START_DATE_OK':
      return {
        ...state,
        isVignetteStartDateOK: action.payload,
      };
    case 'SET_AVAILABLE_COUNTRIES': {
      const availableCountries = [];

      const { countries, vignettes } = state;

      for (const country of countries) {
        let isAnyVignetteForCountry = false;
        const { countryCode } = country;
        if (!vignettes[countryCode]) {
          continue;
        }

        for (const vignette of vignettes[countryCode]) {
          const [vignetteCountryCode] = vignette.code.split('-');
          if (vignetteCountryCode === countryCode) {
            isAnyVignetteForCountry = true;
            break;
          }
        }

        if (isAnyVignetteForCountry) {
          availableCountries.push(country);
        }
      }

      return {
        ...state,
        availableCountries,
      };
    }
    default:
      return state;
  }
};

function getFilteredVehicles(vehicles, _textFilter, _countriesFilter) {
  let filteredVehiclesByText = vehicles;
  if (_textFilter.length > 0) {
    filteredVehiclesByText = filteredVehiclesByText.filter((vehicle) => {
      if (
        vehicle.registrationNumber
          .toLowerCase()
          .includes(_textFilter.toLowerCase())
      ) {
        return true;
      }

      return false;
    });
  }

  const countryCodesFilter = Object.entries(_countriesFilter)
    .filter(([, isFilterActive]) => isFilterActive)
    .reduce((acc, [countryCode]) => {
      if (!acc.includes(countryCode)) {
        acc.push(countryCode);
      }

      return acc;
    }, []);

  let filteredVehiclesByCountryCodes = vehicles;
  if (countryCodesFilter.length > 0) {
    filteredVehiclesByCountryCodes = filteredVehiclesByCountryCodes
      .filter((vehicle) => vehicle.hasActiveCountryCodes)
      .filter((vehicle) => {
        const vignettesCountryCodes = vehicle.vignettes.map(({ code }) => {
          const [vignetteCountryCode] = code.split('-');
          return vignetteCountryCode;
        });

        let matchingCountryCodesCount = 0;
        for (const countryCodeFilter of countryCodesFilter) {
          if (vignettesCountryCodes.includes(countryCodeFilter)) {
            matchingCountryCodesCount += 1;
          }
        }

        const shouldIncludeVehicle =
          matchingCountryCodesCount === countryCodesFilter.length;
        return shouldIncludeVehicle;
      });
  }

  const filteredVehicles = [];
  for (const vehicle of vehicles) {
    const { id: vehicleId } = vehicle;
    const isVehicleInFilteredVehiclesByText = filteredVehiclesByText.find(
      ({ id }) => id === vehicleId
    );
    const isVehicleInFilteredVehiclesByCountryCodes =
      filteredVehiclesByCountryCodes.find(({ id }) => id === vehicleId);

    if (
      isVehicleInFilteredVehiclesByText &&
      isVehicleInFilteredVehiclesByCountryCodes
    ) {
      filteredVehicles.push(vehicle);
    }
  }

  return filteredVehicles.filter(
    (vehicle) =>
      vehicle.isVehicleVisible === true || vehicle.isVehicleVisible == null
  );
}
