/* eslint-disable react/prop-types */
import React from 'react';
import { useDevice } from '../hooks/device';
import Box from '@mui/material/Box';

function CardHeader({ children }) {
  const { isMobile } = useDevice();

  return (
    <Box
      style={{
        fontSize: 16,
        fontWeight: 500,
        ...(isMobile
          ? {
              paddingLeft: '8px',
              paddingRight: '8px',
              marginTop: '8px',
              marginBottom: '16px',
            }
          : {
              paddingLeft: '24px',
              paddingRight: '24px',
              marginTop: '24px',
              marginBottom: '16px',
            }),
      }}
    >
      {children}
    </Box>
  );
}

export default CardHeader;
