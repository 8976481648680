import React from 'react';
import { Helmet } from 'react-helmet';

function Smartsupp() {
  return (
    <Helmet
      script={[
        {
          type: 'text/javascript',
          innerHTML:
            'var _smartsupp=_smartsupp||{};_smartsupp.key="c149bccce93c5b588a21d2efdbe3e45f0b6e6d33";window.smartsupp||(function(d){var s,c,o=smartsupp=function(){o._.push(arguments)};o._=[];s=d.getElementsByTagName("script")[0];c=d.createElement("script");c.type="text/javascript";c.charset="utf-8";c.async=true;c.src="https://www.smartsuppchat.com/loader.js?";s.parentNode.insertBefore(c,s);})(document);',
        },
      ]}
    />
  );
}

export default Smartsupp;
