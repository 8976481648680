/* eslint-disable react/prop-types */
import * as React from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';

const DeviceContext = React.createContext(null);

export const DeviceProvider = ({ children }) => {
  const minWidth576pxMatch = useMediaQuery('(min-width: 576px)');

  const device = {
    isMobile: !minWidth576pxMatch,
    isBrowser: minWidth576pxMatch,
  };

  let deviceRef = React.useRef(device);

  return (
    <DeviceContext.Provider value={deviceRef.current}>
      {children}
    </DeviceContext.Provider>
  );
};

export const useDevice = () => {
  const device = React.useContext(DeviceContext);
  return device;
};
