/* eslint-disable react/prop-types */
import * as React from 'react';

const FeatureFlagsContext = React.createContext({
  featureFlags: undefined,
});

export const FeatureFlagsProvider = ({ children, featureFlags }) => {
  const featureFlagsRef = React.useRef(featureFlags);

  return (
    <FeatureFlagsContext.Provider
      value={{ featureFlags: featureFlagsRef.current }}
    >
      {children}
    </FeatureFlagsContext.Provider>
  );
};

export const useFeatureFlags = () => {
  const { featureFlags } = React.useContext(FeatureFlagsContext);

  if (!featureFlags) {
    throw new Error('Feature flags must be defined');
  }

  return featureFlags;
};
