/* eslint-disable react/prop-types */
import React, { useState, useCallback } from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Card from '@mui/material/Card';
import Table from '@mui/material/Table';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import TableContainer from '@mui/material/TableContainer';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { useVignettesStateValue } from '../../../contexts/vignettesStateContext';
import { useDevice } from '../../../hooks/device';
import { useRepository } from '../../../hooks/api';
import { useCompany } from '../../../hooks/company';
import { useSnackbar } from '../../../hooks/snackbar';
import Plate from '../../../components/Plate';
import TableBox from '../../../components/TableBox';
import ViewHeader from '../../../components/ViewHeader';
import FleetButton from '../../../components/FleetButton';
import FleetTooltip from '../../../components/FleetTooltip';
import ExpirationChip from '../../../components/ExpirationChip';
import {
  getCellStyleForMobile,
  lastRowWithoutBottomBorder,
} from '../../../shared/styles';
import { vignetteLabels, getDateLabel } from '../../../shared/labels';
import AddExternalProductDialog from './AddExternalProductDialog';

export default function VignettesTable({ vignettes, onVignettesRefresh }) {
  const company = useCompany();
  const repository = useRepository();
  const openSnackbar = useSnackbar();
  const { isMobile, isBrowser } = useDevice();

  const [state, dispatch] = useVignettesStateValue();

  const [filter, setFilter] = useState('');
  const [filteredVignettes, setFilteredVignettes] = useState(vignettes);

  const handleOnFilterChange = useCallback((e) => {
    const {
      target: { value: newFilter },
    } = e;
    setFilter(newFilter);
    if (newFilter.length) {
      setFilteredVignettes(
        vignettes.filter((vignette) => {
          if (
            vignette.registrationNumber
              .toLowerCase()
              .includes(newFilter.toLowerCase())
          ) {
            return true;
          }

          return false;
        })
      );
    } else {
      setFilteredVignettes(vignettes);
    }
  }, []);

  const getCellStyle = useCallback(
    (style = {}) => ({
      ...getCellStyleForMobile(isBrowser),
      ...style,
    }),
    []
  );

  const handleShowVignette = useCallback(async (vignette) => {
    try {
      const { vignetteUrl } = await repository.company.getVignette(
        company.taxIdentificationHash,
        vignette.id
      );
      window.open(`${vignetteUrl}`, '_blank', 'noreferrer');
    } catch (e) {
      openSnackbar('Wystąpił błąd pobierania winiety.', 'error');
    }
  }, []);

  const handleToggleAddExternalProductDialog = () => {
    dispatch({
      type: 'SET_IS_ADD_EXTERNAL_PRODUCT_DIALOG_OPEN',
      payload: !state.isAddExternalProductDialogOpen,
    });
  };

  const renderTableBody = () => {
    if (filteredVignettes.length) {
      return filteredVignettes.map((vignette) => (
        <TableRow
          key={`${vignette.id}-${vignette.isExternal}`}
          sx={{ ...lastRowWithoutBottomBorder }}
        >
          <TableCell style={getCellStyle()}>
            {vignetteLabels[vignette.code]}
            {vignette.isExternal ? '*' : ''}
          </TableCell>
          <TableCell style={getCellStyle()}>
            <Plate
              country={vignette.registrationCountry}
              number={vignette.registrationNumber}
            />
          </TableCell>
          {isBrowser ? (
            <>
              <TableCell style={getCellStyle()}>
                {getDateLabel(vignette.validSince)}
              </TableCell>
              <TableCell style={getCellStyle()}>
                {getDateLabel(vignette.validUntil)}
              </TableCell>
            </>
          ) : null}
          <TableCell style={getCellStyle()}>
            <ExpirationChip
              hasStarted={vignette.hasStarted}
              startsIn={vignette.startsIn}
              isStillValid={vignette.isStillValid}
              endsIn={vignette.endsIn}
            />
          </TableCell>
          <TableCell style={getCellStyle()}>
            {vignette.isExternal ? (
              <FleetTooltip title='Pobranie winiety nie jest możliwe, gdyż została zakupiona w zewnętrznym serwisie.'>
                <div>
                  <Button
                    size='small'
                    variant='outlined'
                    disabled
                    style={{ minWidth: 24 }}
                  >
                    <OpenInNewIcon />
                  </Button>
                </div>
              </FleetTooltip>
            ) : (
              <Button
                size='small'
                variant='outlined'
                style={{ minWidth: 24 }}
                onClick={() => handleShowVignette(vignette)}
              >
                <OpenInNewIcon />
              </Button>
            )}
          </TableCell>
        </TableRow>
      ));
    }

    return (
      <TableRow>
        <TableCell
          colSpan={isBrowser ? 6 : 4}
          style={{
            textAlign: 'center',
            fontStyle: 'italic',
          }}
        >
          Nie znaleziono winiet
        </TableCell>
      </TableRow>
    );
  };

  return (
    <>
      <List style={{ flexGrow: '1', padding: 0 }}>
        <ViewHeader>
          <Typography
            variant='h6'
            style={{
              fontSize: 16,
            }}
          >
            Winiety
          </Typography>
        </ViewHeader>
        <Alert severity='info'>
          Tutaj znajduje się chronologiczna lista wszystkich winiet zakupionych za
          pośrednictwem serwisów Flotomat.pl oraz Winiety-Online.pl.
          Aby w pełni umożliwić korzystanie z serwisu <strong>umożliwiamy także wgranie informacji o winietach zakupionych w zewnętrznych serwisach</strong> - winiety można dodać klikając na przycisk &quot;Dodaj winietę&quot;. Gwiazdką (*) przy nazwie oznaczono winiety zakupione w zewnętrznych serwisach.
        </Alert>
        <Card variant='outlined' sx={{ mt: 1, mb: 1 }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              flexDirection: isMobile ? 'column' : 'row',
              ...(isMobile
                ? {
                  pt: 1,
                  pr: 1,
                }
                : {
                  pt: 2,
                  pr: 2,
                }),
            }}
          >
            <Box sx={{ textAlign: 'right' }}>
              <FleetButton
                variant='contained'
                disableElevation
                onClick={handleToggleAddExternalProductDialog}
              >
                Dodaj winietę
              </FleetButton>
            </Box>
          </Box>
          <TableBox>
            <TableContainer>
              <Table style={{ tableLayout: 'fixed' }}>
                <TableHead>
                  <TableRow>
                    <TableCell>Typ winiety</TableCell>
                    <TableCell>Pojazd</TableCell>
                    {isBrowser ? (
                      <>
                        <TableCell>Ważna od</TableCell>
                        <TableCell>Ważna do</TableCell>
                      </>
                    ) : null}
                    <TableCell>Wygasa</TableCell>
                    <TableCell>Akcje</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell />
                    <TableCell>
                      <TextField
                        style={{ maxWidth: 140 }}
                        label='Filtruj'
                        variant='outlined'
                        size='small'
                        value={filter}
                        onChange={handleOnFilterChange}
                      />
                    </TableCell>
                    {isBrowser ? (
                      <>
                        <TableCell />
                        <TableCell />
                      </>
                    ) : null}
                    <TableCell />
                    <TableCell />
                  </TableRow>
                  {renderTableBody()}
                </TableBody>
              </Table>
            </TableContainer>
          </TableBox>
        </Card>
      </List>
      {state.isAddExternalProductDialogOpen && (
        <AddExternalProductDialog
          onClose={handleToggleAddExternalProductDialog}
          onVignettesRefresh={onVignettesRefresh}
        />
      )}
    </>
  );
}
