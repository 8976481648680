import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import CardActions from '@mui/material/CardActions';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useCompany } from '../hooks/company';
import NumberBadge from './NumberBadge';

const CTAButton = styled(Button)({
  textTransform: 'none',
  width: '150px',
});

const UserRegisterPrompter = () => {
  const theme = useTheme();
  const { taxIdentificationHash } = useCompany();

  return (
    <Box
      sx={{ mt: 2 }}
      style={{
        flexGrow: '1',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Card
        variant='outlined'
        sx={{
          maxWidth: '767px',
          padding: 2,
        }}
      >
        <CardContent>
          <Typography
            variant='h6'
            sx={{
              fontSize: 20,
              textAlign: 'center',
              fontWeight: 700,
              color: '#046eb9',
            }}
          >
            Flotomat.pl
          </Typography>
          <Typography
            sx={{
              fontSize: 14,
              fontWeight: 400,
              textAlign: 'center',
            }}
          >
            Zarejestruj się / zaloguj się, aby uzyskać dostęp do tej części
          </Typography>
        </CardContent>
        <CardContent>
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: 14,
              mb: 1.5,
            }}
          >
            Nie masz jeszcze konta?
          </Typography>
          <Typography
            component={'div'}
            sx={{
              fontWeight: 400,
              fontSize: 14,
              mb: 1.5,
            }}
          >
            <NumberBadge bgColor={theme.palette.primary.main} size={20}>
              1
            </NumberBadge>{' '}
            kliknij &quot;Zarejestruj się&quot;, aby przypisać swoją osobę do
            tej firmy,
          </Typography>
          <Typography
            component={'div'}
            sx={{
              fontWeight: 400,
              fontSize: 14,
              mb: 1.5,
            }}
          >
            <NumberBadge bgColor={theme.palette.primary.main} size={20}>
              2
            </NumberBadge>{' '}
            zweryfikuj się jednorazowo jedną z trzech dostępnych metod
            weryfikacji,
          </Typography>
          <Typography
            component={'div'}
            sx={{
              fontWeight: 400,
              fontSize: 14,
            }}
          >
            <NumberBadge bgColor={theme.palette.primary.main} size={20}>
              3
            </NumberBadge>{' '}
            po weryfikacji (maksymalnie 1 dzień roboczy) otrzymasz e-mail z
            dostępem do panelu firmowego.
          </Typography>
        </CardContent>
        <CardActions
          sx={{
            paddingTop: 0,
            justifyContent: 'center',
          }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <CTAButton
              variant='contained'
              disableElevation
              onClick={() => {
                window.location.href = `/firma/${taxIdentificationHash}/zarejestruj-sie`;
              }}
            >
              Zarejestruj się
            </CTAButton>
          </Box>
        </CardActions>
        <CardContent>
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: 14,
              mb: 1.5,
            }}
          >
            Masz już konto?
          </Typography>
          <Typography
            component={'div'}
            sx={{
              fontWeight: 400,
              fontSize: 14,
              mb: 1.5,
            }}
          >
            <NumberBadge bgColor={theme.palette.primary.main} size={20}>
              1
            </NumberBadge>{' '}
            kliknij &quot;Zaloguj się&quot;, aby przejść do panelu firmowego.
          </Typography>
        </CardContent>
        <CardActions
          sx={{
            paddingTop: 0,
            justifyContent: 'center',
          }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <CTAButton
              variant='outlined'
              onClick={() => {
                window.location.href = `/firma/${taxIdentificationHash}/zaloguj-sie`;
              }}
            >
              Zaloguj się
            </CTAButton>
          </Box>
        </CardActions>
        <hr />
        <CardContent>
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: 14,
              mb: 1.5,
            }}
          >
            Korzyści:
          </Typography>
          <Typography
            component={'div'}
            sx={{
              fontSize: 14,
              mb: 1.5,
            }}
          >
            <CheckCircleOutlineIcon
              sx={{ color: theme.palette.primary.main }}
            />{' '}
            wygodne zarządzanie winietami z jednego miejsca,
          </Typography>
          <Typography
            component={'div'}
            sx={{
              fontSize: 14,
              mb: 1.5,
            }}
          >
            <CheckCircleOutlineIcon
              sx={{ color: theme.palette.primary.main }}
            />{' '}
            mniejsze ryzyko błędów i mandatów,
          </Typography>
          <Typography
            sx={{
              fontSize: 14,
            }}
          >
            <CheckCircleOutlineIcon sx={{ color: '#43A047' }} /> dla klientów z
            serwisu Winiety-Online.pl korzystanie z panelu firmowego jest{' '}
            <strong style={{ color: '#43A047' }}>bezpłatne</strong>.
          </Typography>
        </CardContent>
      </Card>
    </Box>
  );
};

export default UserRegisterPrompter;
