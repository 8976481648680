import React, { useEffect } from 'react';
import { useCompany } from '../hooks/company';
import { useRepository } from '../hooks/api';
import { initialState, reducer } from '../contexts/statusReducer';
import {
  StatusStateProvider,
  useStatusStateValue,
} from '../contexts/statusStateContext';
import Loader from '../components/Loader';
import StatusTable from '../views/vehicles/StatusTable/StatusTable';
import { vignetteLabels } from '../shared/labels';

export default function FleetStatus() {
  return (
    <StatusStateProvider initialState={initialState} reducer={reducer}>
      <StatefulFleetStatus />
    </StatusStateProvider>
  );
}

function StatefulFleetStatus() {
  const company = useCompany();
  const repository = useRepository();

  const [state, dispatch] = useStatusStateValue();

  const init = async () => {
    const { taxIdentificationHash } = company;
    const [vehicles, products] = await Promise.all([
      repository.company.getStatus(taxIdentificationHash),
      repository.company.getProducts(taxIdentificationHash),
    ]);

    dispatch({ type: 'SET_VEHICLES', payload: vehicles });
    dispatch({ type: 'SET_FILTERED_VEHICLES', payload: vehicles });

    const vignettes = getVignettes(products);
    dispatch({ type: 'SET_VIGNETTES', payload: vignettes });

    dispatch({ type: 'SET_IS_LOADING_VEHICLES', payload: false });
  };

  useEffect(() => {
    init();
  }, []);

  const handleVehiclesRefresh = async () => {
    dispatch({ type: 'SET_IS_LOADING_VEHICLES', payload: true });
    await init();
  };

  return state.isLoadingVehicles ? (
    <Loader />
  ) : (
    <StatusTable onVehiclesRefresh={handleVehiclesRefresh} />
  );
}

function getVignettes(products) {
  return products
    .filter((product) => product.isActive)
    .reduce((acc, product) => {
      const { code } = product;
      const [countryCode] = code.split('-');
      if (acc[countryCode] == null) {
        acc[countryCode] = [];
      }

      const label = vignetteLabels[code];
      const { grossPrice, position } = product;
      acc[countryCode].push({
        code,
        label,
        grossPrice,
        position,
      });

      return acc;
    }, {});
}
