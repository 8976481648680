import React from 'react';
import List from '@mui/material/List';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useDevice } from '../hooks/device';
import Smartsupp from '../components/Smartsupp';
import CardHeader from '../components/CardHeader';

export default function Contact() {
  const { isMobile } = useDevice();

  return (
    <List style={{ flexGrow: '1', padding: 0 }}>
      <Card
        variant='outlined'
        sx={{
          mt: 1,
          mb: 1,
          ...(isMobile ? { p: 1 } : { p: 3 }),
        }}
      >
        <Typography variant='h6' style={{ fontSize: 16 }}>
          Kontakt
        </Typography>
      </Card>
      <Card variant='outlined' sx={{ mt: 1, mb: 1 }}>
        <CardHeader>Kanały komunikacji</CardHeader>
        <Box
          sx={{
            ...(isMobile
              ? {
                  pl: 1,
                  pr: 1,
                  pb: 1,
                }
              : {
                  pl: 3,
                  pr: 3,
                  pb: 3,
                }),
            fontSize: 14,
          }}
        >
          <p>
            Mają Państwo pytania odnośnie funkcjonowania serwisu lub chcą
            Państwo zaproponować nową funkcjonalność?
          </p>
          <div style={{ paddingBottom: 16 }}>
            Zachęcamy do nawiązywania kontaktu przez główne kanały komunikacji,
            tj:
            <ul style={{ marginTop: 5 }}>
              <li style={{ marginBottom: 5 }}>
                skrzynka e-mail, tj.{' '}
                <strong>
                  <a href='mailto: kontakt@flotomat.pl'>kontakt@flotomat.pl</a>
                </strong>
              </li>
              <li style={{ marginBottom: 5 }}>
                kontakt telefoniczny, tj.{' '}
                <strong>
                  <a href='tel:+48881265093'>+48 881 265 093</a>
                </strong>
              </li>
              <li style={{ marginBottom: 5 }}>
                czat dostępny w prawym dolnym rogu ekranu
              </li>
            </ul>
          </div>
          <p style={{ margin: 0, textAlign: 'right' }}>
            Obsługa serwisu Flotomat.pl
          </p>
        </Box>
      </Card>
      <Smartsupp />
    </List>
  );
}
