export const initialState = {
  isAddExternalProductDialogOpen: false,
  vehicle: {
    registrationCountry: null,
    registrationNumber: null,
  },
  vignette: {
    countryCode: null,
    type: null,
    period: null,
    startDate: new Date(),
    grossPrice: null,
  },
  isVignetteStartDateOK: true,
};

export const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_IS_ADD_EXTERNAL_PRODUCT_DIALOG_OPEN':
      return { ...state, isAddExternalProductDialogOpen: action.payload };
    case 'SET_INITIAL_STATE':
      return {
        ...state,
        vignette: {
          countryCode: 'AT',
          type: 'EV2',
          period: '10D',
          startDate: new Date(),
        },
        vehicle: {
          registrationCountry: 'Polska',
          registrationNumber: '',
        },
      };
    case 'SET_VEHICLE_PARAMS':
      return {
        ...state,
        vehicle: {
          ...state.vehicle,
          ...action.payload,
        },
      };
    case 'SET_VIGNETTE_PARAMS':
      return {
        ...state,
        vignette: {
          ...state.vignette,
          ...action.payload,
        },
      };
    case 'SET_IS_VIGNETTE_START_DATE_OK':
      return {
        ...state,
        isVignetteStartDateOK: action.payload,
      };
    default:
      return state;
  }
};
