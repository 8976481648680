export const displayPrice = (value) => {
  const valueAsNumber = Number(value);
  const valueAsNumberRounded = Math.round(valueAsNumber * 100) / 100;
  return new Intl.NumberFormat('pl-PL', {
    style: 'currency',
    currency: 'PLN',
  }).format(valueAsNumberRounded);
};

export const displayDiscount = (value) => {
  const valueAsNumber = Number(value);
  return new Intl.NumberFormat('pl-PL', {
    style: 'percent',
    minimumFractionDigits: 1,
    maximumFractionDigits: 2,
  }).format(valueAsNumber);
};
