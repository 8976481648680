import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';
import Loader from '../components/Loader';
import ViewHeader from '../components/ViewHeader';
import { useDevice } from '../hooks/device';
import { useRepository } from '../hooks/api';
import { useCompany } from '../hooks/company';
import MessagesTable from '../views/messages/MessagesTable/MessagesTable';

export default function Desktop() {
  const company = useCompany();
  const repository = useRepository();
  const { isBrowser } = useDevice();

  const [messages, setMessages] = useState([]);
  const [isLoadingData, setIsLoadingData] = useState(true);

  const init = async () => {
    const messages = await repository.company.getMessages(
      company.taxIdentificationHash
    );
    setMessages(messages);
    setIsLoadingData(false);
  };

  useEffect(() => {
    init();
  }, []);

  return isLoadingData ? (
    <Loader />
  ) : (
    <Box style={{ flexGrow: '1', padding: 0 }}>
      <ViewHeader>
        <Typography
          variant='h6'
          style={{
            fontSize: 16,
          }}
        >
          Pulpit
        </Typography>
      </ViewHeader>
      <Alert severity='info' sx={{ mb: 1 }}>
        Zachęcamy do zapoznania się z najnowszymi informacjami dotyczącymi
        aplikacji Flotomat.pl.
      </Alert>
      <Box
        sx={{
          display: 'flex',
          mt: 1,
          mb: 1,
          gap: 1,
          ...(isBrowser
            ? { flexDirection: 'row' }
            : { flexDirection: 'column' }),
        }}
      >
        <MessagesTable messages={messages} />
      </Box>
    </Box>
  );
}
