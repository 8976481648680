export const countryLabels = {
  BG: 'Bułgaria',
  AT: 'Austria',
  CZ: 'Czechy',
  HU: 'Węgry',
  SK: 'Słowacja',
  SI: 'Słowenia',
  RO: 'Rumunia',
};

export const vignetteLabels = {
  'CZ-V-1D': 'Czechy wskazany dzień',
  'CZ-V-10D': 'Czechy 10 dni',
  'CZ-V-1M': 'Czechy 30 dni',
  'CZ-V-1Y': 'Czechy 365 dni',
  'AT-EV2-1D': 'Austria wskazany dzień',
  'AT-EV2-10D': 'Austria 10 dni',
  'AT-EV2-2M': 'Austria 2 miesiące',
  'AT-EV2-1Y': 'Austria 1 rok',
  'SK-V-1D': 'Słowacja 1 dzień',
  'SK-V-10D': 'Słowacja 10 dni',
  'SK-V-30D': 'Słowacja 30 dni',
  'SK-V-1Y': 'Słowacja 365 dni',
  'SK-TR-1D': 'Słowacja 1 dzień (przyczepa)',
  'SK-TR-10D': 'Słowacja 10 dni (przyczepa)',
  'SK-TR-30D': 'Słowacja 30 dni (przyczepa)',
  'SK-TR-1Y': 'Słowacja 365 dni (przyczepa)',
  'HU-D1M-1D': 'Węgry D1M wskazany dzień',
  'HU-D1M-10D': 'Węgry D1M 10 dni',
  'HU-D1M-1M': 'Węgry D1M 1 miesiąc',
  'HU-D1-1D': 'Węgry D1 wskazany dzień',
  'HU-D1-10D': 'Węgry D1 10 dni',
  'HU-D1-1M': 'Węgry D1 1 miesiąc',
  'HU-D1-1Y': 'Węgry D1 1 rok',
  'HU-D2-1D': 'Węgry D2 wskazany dzień',
  'HU-D2-10D': 'Węgry D2 10 dni',
  'HU-D2-1M': 'Węgry D2 1 miesiąc',
  'HU-D2-1Y': 'Węgry D2 1 rok',
  'HU-TR-1D': 'Węgry U wskazany dzień (przyczepa)',
  'HU-TR-10D': 'Węgry U 10 dni (przyczepa)',
  'HU-TR-1M': 'Węgry U 1 miesiąc (przyczepa)',
  'HU-TR-1Y': 'Węgry U 1 rok (przyczepa)',
  'SI-2A-7D': 'Słowenia 2A 7 dni',
  'SI-2A-1M': 'Słowenia 2A 1 miesiąc',
  'SI-2A-1Y': 'Słowenia 2A 365 dni',
  'SI-2B-7D': 'Słowenia 2B 7 dni',
  'SI-2B-1M': 'Słowenia 2B 1 miesiąc',
  'SI-2B-1Y': 'Słowenia 2B 365 dni',
  'RO-V1-1D': 'Rumunia A wskazany dzień',
  'RO-V1-7D': 'Rumunia A 7 dni',
  'RO-V1-10D': 'Rumunia A 10 dni',
  'RO-V1-30D': 'Rumunia A 30 dni',
  'RO-V1-90D': 'Rumunia A 90 dni',
  'RO-V1-1Y': 'Rumunia A 365 dni',
  'RO-V2-1D': 'Rumunia B wskazany dzień',
  'RO-V2-7D': 'Rumunia B 7 dni',
  'RO-V2-10D': 'Rumunia B 10 dni',
  'RO-V2-30D': 'Rumunia B 30 dni',
  'RO-V2-90D': 'Rumunia B 90 dni',
  'RO-V2-1Y': 'Rumunia B 365 dni',
  'BG-V-7D': 'Bułgaria 7 dni',
  'BG-V-1M': 'Bułgaria 1 miesiąc',
  'BG-V-3M': 'Bułgaria 3 miesiące',
  'BG-V-1Y': 'Bułgaria 365 dni',
  'CH-V1-1Y': 'Szwajcaria 1 rok',
  'CH-V2-1Y': 'Szwajcaria 1 rok',
  'CH-V3-1Y': 'Szwajcaria 1 rok',
};

export const countryCodes = {
  Polska: 'PL',
};

export const getLicensePlateLabel = (country, registrationNumber) => {
  return `${countryCodes[country]} ${registrationNumber}`;
};

export const getShortVinNumber = (vinNumber) => {
  if (!vinNumber) {
    return '';
  }

  if (vinNumber.length <= 3) {
    return vinNumber;
  }

  return vinNumber.substring(0, 3) + '...';
};

export const getDateLabel = (dateTime) => {
  const [date] = dateTime.split('T');
  const [year, month, day] = date.split('-');
  return `${day}-${month}-${year}`;
};

export const getExpirationLabel = (endsIn) => {
  if (endsIn === 0) {
    return 'dzisiaj';
  }

  if (endsIn === 1) {
    return 'jutro';
  }

  return `za ${endsIn} dni`;
};
