/* eslint-disable react/prop-types */
import React, { useMemo } from 'react';
import propTypes from 'prop-types';
import { useImages } from '../hooks/images/index';
import { useDevice } from '../hooks/device/index';
import { COUNTRY_LABEL_TO_COUNTRY_CODE } from '../shared/constants';

function Plate({ country, number, isActive = true }) {
  const images = useImages();
  const { isBrowser } = useDevice();

  const image = useMemo(() => {
    const countryCode = COUNTRY_LABEL_TO_COUNTRY_CODE[country];
    if (!countryCode) {
      return null;
    }

    const image = images.registrationCountries[countryCode];
    if (!image) {
      return null;
    }

    return image;
  }, [country]);

  const opacity = isActive ? 1 : 0.5;

  return (
    <div style={{ display: 'flex' }}>
      <div
        style={{
          opacity,
          display: 'flex',
          flexDirection: 'row',
          height: '40px',
          lineHeight: '40px',
          border: '1px solid #e1e3e4',
          borderRadius: '5px',
          ...(isBrowser
            ? {}
            : {
                height: '35px',
                lineHeight: '35px',
              }),
        }}
      >
        {image ? (
          <img
            src={image}
            style={{
              borderTopLeftRadius: '3px',
              borderBottomLeftRadius: '3px',
            }}
          />
        ) : null}
        <div
          style={{
            ...(isBrowser
              ? { paddingLeft: '10px', paddingRight: '10px' }
              : { paddingLeft: '3px', paddingRight: '3px' }),
          }}
        >
          <span
            style={{
              fontFamily: 'Source Code Pro',
              fontWeight: 500,
              ...(isBrowser ? { fontSize: 18 } : { fontSize: 14 }),
            }}
          >
            {number}
          </span>
        </div>
      </div>
    </div>
  );
}

Plate.propTypes = {
  country: propTypes.string.isRequired,
  number: propTypes.string.isRequired,
  isActive: propTypes.bool,
};

export default Plate;
