import * as React from 'react';
import createCompany from './company';
import createCalculator from './calculator';

// eslint-disable-next-line no-undef
const nodeEnv = process.env.NODE_ENV;
// eslint-disable-next-line no-undef
const appHost = process.env.REACT_APP_APP_HOST;
// eslint-disable-next-line no-undef
const calculatorHost = process.env.REACT_APP_CALCULATOR_HOST;

const ApiContext = React.createContext({
  repository: undefined,
  clients: undefined,
});

// eslint-disable-next-line react/prop-types
export const ApiProvider = ({ children }) => {
  const { companyClient, companyRepository } = createCompany(nodeEnv, appHost);
  const { calculatorClient, calculatorRepository } = createCalculator(
    nodeEnv,
    calculatorHost
  );

  const repositoryRef = React.useRef({
    company: companyRepository,
    calculator: calculatorRepository,
  });

  const clientRef = React.useRef({
    company: companyClient,
    calculator: calculatorClient,
  });

  return (
    <ApiContext.Provider
      value={{
        repository: repositoryRef.current,
        client: clientRef.current,
      }}
    >
      {children}
    </ApiContext.Provider>
  );
};

export const useRepository = () => {
  const { repository } = React.useContext(ApiContext);

  if (!repository) {
    throw new Error('Repository must be defined');
  }

  return repository;
};

export const useClient = () => {
  const { client } = React.useContext(ApiContext);

  if (!client) {
    throw new Error('API Client must be defined');
  }

  return client;
};
