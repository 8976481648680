export const VEHICLES_TABLE_COLUMN_WIDTHS = {
  COLUMN_1: '50px',
  COLUMN_2: '25%',
};

export const COUNTRY_CODE_TO_COUNTRY_LABEL = {
  PL: 'Polska',
  AT: 'Austria',
  DE: 'Niemcy',
};

export const COUNTRY_LABEL_TO_COUNTRY_CODE = {
  Polska: 'PL',
  Austria: 'AT',
  Niemcy: 'DE',
};
