import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useUser } from '../hooks/user';
import { useDevice } from '../hooks/device';
import { useRepository } from '../hooks/api';
import { useCompany } from '../hooks/company';
import { useImages } from '../hooks/images/index';
import Loader from '../components/Loader';
import ViewHeader from '../components/ViewHeader';
import CardHeader from '../components/CardHeader';
import BuyVignetteButton from '../components/BuyVignetteButton';
import { generateRedirectQuery } from '../shared/urls';

export default function PromotionCodes() {
  const user = useUser();
  const company = useCompany();
  const repository = useRepository();
  const images = useImages();
  const { isMobile, isBrowser } = useDevice();

  const [promotionCodes, setPromotionCodes] = useState([]);
  const [isLoadingData, setIsLoadingData] = useState(true);

  const init = async () => {
    const promotionCodes = await repository.company.getPromotionCodes(
      company.taxIdentificationHash
    );
    setPromotionCodes(
      promotionCodes
        .filter(({ active }) => active === true)
        .filter(({ discountType }) => ['1', '2'].includes(discountType))
        .map(({ id, name, code, discount, discountType }) => ({
          id,
          name,
          code,
          discount,
          discountType: discountType === '1' ? 'percentage' : 'amount',
        }))
    );
    setIsLoadingData(false);
  };

  useEffect(() => {
    init();
  }, []);

  const handleBuyVignettesButtonClick = () => {
    const redirectQuery = generateRedirectQuery(company, user);
    window.open(`https://winiety-online.pl?${redirectQuery}`, '_blank');
  };

  const getPromotionCodeLabel = (promotionCode) => {
    const { discount, discountType } = promotionCode;
    const discountLabel =
      discountType === 'percentage'
        ? `Procentowy (${discount}%)`
        : `Kwotowy (${discount} zł)`;

    return discountLabel;
  };

  const handleCopyPromotionCodeClick = (promotionCode) => {
    const { code } = promotionCode;
    navigator.clipboard.writeText(code);
  };

  const shouldDisplayPromotionCodes = promotionCodes.length > 0;

  return isLoadingData ? (
    <Loader />
  ) : (
    <Box style={{ flexGrow: '1', padding: 0 }}>
      <ViewHeader>Rabaty dla NIP {company.taxIdentificationNumber}</ViewHeader>
      <Box
        sx={{
          display: 'flex',
          mt: 1,
          mb: 1,
          gap: 1,
          ...(isBrowser
            ? { flexDirection: 'row' }
            : { flexDirection: 'column' }),
        }}
      >
        <Card variant='outlined' style={{ width: '100%', height: '100%' }}>
          <Box style={{ display: 'flex', flexDirection: 'column' }}>
            <Box>
              <CardHeader>
                Informacje o przydzielonych kuponach rabatowych
              </CardHeader>
            </Box>
            <Box
              sx={{
                ...(isMobile
                  ? { pl: 1, pr: 1, pb: 1 }
                  : { pl: 3, pr: 3, pb: 3 }),
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                fontSize: 14,
              }}
            >
              <Box sx={{ pb: 2 }}>
                Poniżej znajduje się lista wszystkich przydzielonych
                indywidualnych kuponów rabatowych:
              </Box>
              <Box>
                {shouldDisplayPromotionCodes ? (
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: 2,
                    }}
                  >
                    {promotionCodes.map((pC) => (
                      <Box
                        key={pC.id}
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          p: 1,
                          color: '#098a00',
                          borderRadius: '4px',
                          backgroundColor: 'rgb(223, 241, 255)',
                        }}
                      >
                        <Box sx={{ pR: 1 }}>{getPromotionCodeLabel(pC)}</Box>
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 1,
                          }}
                        >
                          <TextField
                            sx={{
                              '& fieldset': { border: 'none' },
                            }}
                            size='small'
                            value={pC.code}
                            inputProps={{
                              style: {
                                padding: 6,
                                fontSize: 14,
                                fontWeight: 500,
                                color: '#098a00',
                                textAlign: 'right',
                                maxWidth: 130,
                                border: 'none',
                              },
                            }}
                          />
                          <Button
                            size='small'
                            variant='text'
                            style={{ minWidth: 24, fontWeight: 400 }}
                            onClick={() => handleCopyPromotionCodeClick(pC)}
                          >
                            <ContentCopyIcon sx={{ color: '#cacaca' }} />
                          </Button>
                        </Box>
                      </Box>
                    ))}
                  </Box>
                ) : (
                  <Box
                    sx={{
                      p: 1,
                      textAlign: 'center',
                      fontStyle: 'italic',
                      borderRadius: '4px',
                      border: '1px solid rgba(224, 224, 224, 1)',
                      backgroundColor: '#f5f5f5',
                      color: 'rgb(99, 99, 99)',
                    }}
                  >
                    Nie znaleziono kuponów rabatowych.
                    <br />
                    Aby otrzymać kupon rabatowy skontaktuj się z obsługą
                    klienta.
                  </Box>
                )}
              </Box>
              {shouldDisplayPromotionCodes ? (
                <Box sx={{ pt: 2 }}>
                  <p>
                    Wykup winiety w serwisie Winiety-Online.pl i skorzystaj z
                    kuponów rabatowych:
                  </p>
                  <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <BuyVignetteButton onClick={handleBuyVignettesButtonClick}>
                      Kup Winiety
                    </BuyVignetteButton>
                  </Box>
                </Box>
              ) : null}
            </Box>
          </Box>
        </Card>
        <Card variant='outlined' style={{ width: '100%' }}>
          <Box style={{ display: 'flex', flexDirection: 'column' }}>
            <Box>
              <CardHeader>
                Instrukcja korzystania z kuponu rabatowego
              </CardHeader>
            </Box>
            <Box
              sx={{
                ...(isMobile
                  ? { pl: 1, pr: 1, pb: 1 }
                  : { pl: 3, pr: 3, pb: 3 }),
                fontSize: 14,
              }}
            >
              <p>
                Po dodaniu winiet do koszyka kliknij{' '}
                <strong>Mam kupon rabatowy</strong>, następnie wklej skopiowany
                wcześniej kod do nowo otwartego pola i na koniec kliknij{' '}
                <strong>Użyj</strong>.
              </p>
              <div style={{ textAlign: 'center', marginBottom: '1rem' }}>
                <img
                  style={{ maxWidth: '300px', width: '100%', borderRadius: 4 }}
                  src={images.promotionCodesUrl}
                />
              </div>
              <p>
                Po naliczeniu rabatu możesz bezpiecznie kontynuować proces
                zamawiania winiet.
              </p>
              <p style={{ textAlign: 'right', marginBottom: 0 }}>
                Życzymy bezpiecznych podróży!
              </p>
            </Box>
          </Box>
        </Card>
      </Box>
    </Box>
  );
}
