import React from 'react';
import { useTheme } from '@mui/material/styles';

export default function BrandName() {
  const theme = useTheme();

  return (
    <span
      style={{
        color: theme.palette.primary.main,
        fontWeight: 700,
      }}
    >
      Flotomat.pl
    </span>
  );
}
