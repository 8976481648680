import { camelizeKeys, decamelizeKeys } from 'humps';

export const camelize = (response) => {
  const newData = camelizeKeys(response.data, (key, convert) => {
    if (key.includes('_')) {
      return convert(key);
    }

    return key;
  });

  response.data = newData;
  return response;
};

export const useCamelizeResponse = (client) => {
  client.interceptors.response.use(
    (response) => {
      if (
        response.data &&
        response.headers['content-type'].includes('application/json')
      ) {
        response = camelize(response);
      }

      return response;
    },
    (error) => Promise.reject(error)
  );
};

export const useDecamelizeRequest = (client) => {
  client.interceptors.request.use(
    (config) => {
      const newConfig = { ...config };
      if (newConfig.headers['Content-Type'] === 'multipart/form-data') {
        return newConfig;
      }

      if (config.params) {
        newConfig.params = decamelizeKeys(config.params);
      }

      if (config.data) {
        newConfig.data = decamelizeKeys(config.data);
      }

      return newConfig;
    },
    (error) => Promise.reject(error)
  );
};
