import React from 'react';
import HomeIcon from '@mui/icons-material/Home';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import PaidIcon from '@mui/icons-material/Paid';

export const createMenuItemsBlock1 = (taxIdentificationHash) => [
  {
    label: 'Pulpit',
    to: `/firma/${taxIdentificationHash}`,
    icon: <HomeIcon />,
  },
];

export const createMenuItemsBlock2 = (taxIdentificationHash) => [
  {
    label: 'Winiety',
    to: `/firma/${taxIdentificationHash}/winiety`,
    icon: <ReceiptLongIcon />,
  },
  {
    label: 'Zamówienia',
    to: `/firma/${taxIdentificationHash}/zamowienia`,
    icon: <ShoppingCartIcon />,
  },
  {
    label: 'Faktury',
    to: `/firma/${taxIdentificationHash}/faktury`,
    icon: <PaidIcon />,
  },
];
