/* eslint-disable react/prop-types */
import React from 'react';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import FleetButton from './FleetButton';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { useCompany } from '../hooks/company';
import { useDevice } from '../hooks/device';

function ViewHeader({ children }) {
  const company = useCompany();
  const { isMobile } = useDevice;

  return (
    <Card
      variant='outlined'
      sx={{
        mt: 1,
        mb: 1,
        display: 'flex',
        justifyContent: 'space-between',
        ...(isMobile ? { padding: '8px' } : { padding: '16px' }),
      }}
    >
      <Box
        variant='h6'
        style={{
          fontSize: 16,
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {children}
      </Box>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <FleetButton
          component={Link}
          to={`/firma/${company.taxIdentificationHash}/koszyk`}
        >
          <ShoppingCartIcon />
          <div style={{ marginLeft: '0.5rem' }}>Koszyk</div>
        </FleetButton>
      </div>
    </Card>
  );
}

export default ViewHeader;
