/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { pl } from 'date-fns/locale/pl';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Stack from '@mui/material/Stack';
import Alert from '@mui/material/Alert';
import Dialog from '@mui/material/Dialog';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { useVignettesStateValue } from '../../../contexts/vignettesStateContext';
import Flag from '../../../components/Flag/Flag';
import FleetButton from '../../../components/FleetButton';
import { useCompany } from '../../../hooks/company';
import { useRepository } from '../../../hooks/api';
import { useSnackbar } from '../../../hooks/snackbar';
import { vignetteLabels, getLicensePlateLabel } from '../../../shared/labels';
import './AddExternalProductDialog.scss';

export default function AddExternalProductDialog({ onClose, onVignettesRefresh }) {
  const company = useCompany();
  const repository = useRepository();
  const openSnackbar = useSnackbar();

  const [state, dispatch] = useVignettesStateValue();

  useEffect(() => {
    dispatch({ type: 'SET_INITIAL_STATE' });
  }, []);

  const handleVehicleRegistrationNumberChange = (event) => {
    dispatch({
      type: 'SET_VEHICLE_PARAMS',
      payload: {
        registrationNumber: event.target.value.trim().replace(/\s/g, '').toUpperCase(),
      },
    });
  };

  const handleVignetteCountryChange = (event) => {
    const countryCode = event.target.value;
    dispatch({
      type: 'SET_VIGNETTE_PARAMS',
      payload: {
        countryCode,
      },
    });
    // TODO: Handle other countries
    // dispatch({
    //   type: 'SET_AVAILABLE_VIGNETTES',
    //   payload: countryCode,
    // });
    // dispatch({
    //   type: 'SET_FIRST_AVAILABLE_VIGNETTE',
    // });
  };

  const handleVignetteTypeChange = (event) => {
    const vignette = event.target.value;
    const [countryCode, type, period] = vignette.split('-');
    dispatch({
      type: 'SET_VIGNETTE_PARAMS',
      payload: {
        countryCode,
        type,
        period,
      },
    });
  };

  const handleStartDateValueChange = (startDate) => {
    dispatch({
      type: 'SET_VIGNETTE_PARAMS',
      payload: {
        startDate,
      },
    });
  };

  const handleStartDateErrorChange = (error) => {
    const isVignetteStartDateOK = error === null;
    dispatch({
      type: 'SET_IS_VIGNETTE_START_DATE_OK',
      payload: isVignetteStartDateOK,
    });
  };

  const handleSubmitButton = async () => {
    const { vehicle, vignette } = state;

    const vignetteCode = `${vignette.countryCode}-${vignette.type}-${vignette.period}`;
    // TODO: Unfiy this with other place like that
    const vignetteStartDate = vignette.startDate.toISOString().slice(0, 10);

    try {
      const response = await repository.company.createExternalProduct(
        company.taxIdentificationHash,
        {
          registration_country: vehicle.registrationCountry,
          registration_number: vehicle.registrationNumber,
          code: vignetteCode,
          valid_from: vignetteStartDate,
        }
      );

      onClose();
      await onVignettesRefresh();

      const { status } = response;
      if (status === 201) {
        const { data } = response;
        const {
          fleetVehicle: { registrationCountry, registrationNumber },
        } = data;
        const licensePlateLabel = getLicensePlateLabel(
          registrationCountry,
          registrationNumber
        );
        openSnackbar(`Dodano winietę dla pojazdu ${licensePlateLabel}`, 'success');
      }
    } catch (e) {
      const { response } = e;
      if (response.status === 422) {
        if (
          response.data.errors.includes('Entity already exists')
        ) {
          openSnackbar(`Winieta o podanych danych już istnieje`, 'error');
        } else {
          openSnackbar(`Nie udało się dodać winiety`, 'error');
        }
      } else {
        openSnackbar(`Nie udało się dodać winiety`, 'error');
      }
    }
  };

  const { vignette } = state;
  const selectedVignetteCode =
    vignette.countryCode && vignette.type && vignette.period
      ? `${vignette.countryCode}-${vignette.type}-${vignette.period}`
      : null;

  const availableCountries = [{ countryCode: 'AT', label: 'Austria' }];

  const availableVignettes = [
    { code: 'AT-EV2-1D', label: vignetteLabels['AT-EV2-1D'] },
    { code: 'AT-EV2-10D', label: vignetteLabels['AT-EV2-10D'] },
    { code: 'AT-EV2-2M', label: vignetteLabels['AT-EV2-2M'] },
    { code: 'AT-EV2-1Y', label: vignetteLabels['AT-EV2-1Y'] },
  ];

  const { vehicle } = state;
  const availableRegistrationCountries = [{ countryCode: 'Polska', label: 'Polska' }];

  const isSubmitButtonDisabled = !(
    state.isVignetteStartDateOK
    && vehicle.registrationNumber
  );

  return (
    <React.Fragment>
      <Dialog open={state.isAddExternalProductDialogOpen} onClose={onClose}>
        <DialogTitle>Dodaj winietę zakupioną w innym serwisie</DialogTitle>
        <DialogContent>
          <Alert severity='info'>
            W tym oknie można dodać informacje o winiecie zakupionej
            w zewnętrznym serwisie. Po dodaniu danych informacje o winiecie
            zostaną wyświetlone w sekcji Moja Flota - Pojazdy.
          </Alert>
          <DialogContentText sx={{ mt: 2, mb: 2 }}>
            Wprowadź dane pojazdu oraz dane winiety.
          </DialogContentText>
          <Stack spacing={2}>
            {vehicle.registrationCountry && (
              <FormControl required fullWidth variant='standard' size='small'>
                <InputLabel id='vehicle-registration-country-label'>Kraj rejestracji pojazdu</InputLabel>
                <Select
                  labelId='vehicle-registration-country-label'
                  id='vehicle-registration-country-select'
                  label='Kraj rejestracji pojazdu'
                  value={vehicle.registrationCountry}
                  disabled
                  onChange={() => { }}
                >
                  {availableRegistrationCountries.map((aRC) => {
                    return (
                      <MenuItem key={aRC.countryCode} value={aRC.countryCode}>
                        <div className='AddVignetteDialog__CountrySelectOption'>
                          {aRC.label}
                        </div>
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            )}
            <TextField
              required
              margin='dense'
              id='vehicle-registration-number-text-field'
              label='Numer rejestracyjny pojazdu'
              type='text'
              fullWidth
              variant='standard'
              size='small'
              placeholder="SK123PN"
              value={vehicle.registrationNumber}
              onChange={handleVehicleRegistrationNumberChange}
            />
            {vignette.countryCode && (
              <FormControl required fullWidth variant='standard' size='small'>
                <InputLabel id='vignette-country-label'>Winieta do kraju</InputLabel>
                <Select
                  labelId='vignette-country-label'
                  id='vignette-country-select'
                  label='Winieta do kraju'
                  value={vignette.countryCode}
                  onChange={handleVignetteCountryChange}
                >
                  {availableCountries.map((aC) => {
                    return (
                      <MenuItem key={aC.countryCode} value={aC.countryCode}>
                        <div className='AddVignetteDialog__CountrySelectOption'>
                          <Flag
                            key={aC.countryCode}
                            countryCode={aC.countryCode}
                            style={{ marginRight: '10px' }}
                          />
                          {aC.label}
                        </div>
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            )}
            {selectedVignetteCode && (
              <FormControl required fullWidth variant='standard' size='small'>
                <InputLabel id='vignette-type-label'>Typ winiety</InputLabel>
                <Select
                  labelId='vignette-type-label'
                  id='vignette-type-select'
                  label='Okres obowiązywania winiety'
                  value={selectedVignetteCode}
                  onChange={handleVignetteTypeChange}
                >
                  {availableVignettes.map((aV) => {
                    return (
                      <MenuItem key={aV.code} value={aV.code}>
                        {aV.label}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            )}
            <FormControl style={{ paddingTop: '1rem' }}>
              <LocalizationProvider
                dateAdapter={AdapterDateFns}
                adapterLocale={pl}
              >
                <DatePicker
                  label='Winieta ważna od*'
                  format='dd-MM-yyyy'
                  value={vignette.startDate}
                  onChange={(newStartDate) =>
                    handleStartDateValueChange(newStartDate)
                  }
                  onError={(error) => handleStartDateErrorChange(error)}
                />
              </LocalizationProvider>
            </FormControl>
          </Stack>
        </DialogContent>
        <DialogActions sx={{ padding: '16px 24px' }}>
          <FleetButton onClick={onClose}>Anuluj</FleetButton>
          <FleetButton
            onClick={handleSubmitButton}
            disabled={isSubmitButtonDisabled}
          >
            Dodaj
          </FleetButton>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
