/* eslint-disable react/prop-types */
import React from 'react';
import { useDevice } from '../hooks/device';
import Box from '@mui/material/Box';

function TableBox({ children }) {
  const { isMobile } = useDevice();

  return (
    <Box
      sx={{
        ...(isMobile
          ? {
              pl: 0,
              pr: 0,
              pb: 0,
            }
          : {
              pt: 2,
              pr: 2,
              pb: 2,
              pl: 2,
            }),
      }}
    >
      {children}
    </Box>
  );
}

export default TableBox;
