import React from 'react'

// eslint-disable-next-line react/prop-types
const NumberBadge = ({ bgColor, size, children }) => {
    const style = {
        backgroundColor: bgColor,
        borderRadius: '100%',
        display: 'inline-flex',
        width: size,
        height: size,
        justifyContent: 'center',
        alignItems: 'center',
        color: '#fff',
        textWeight: 'bold',
    };

    return <div style={style}>{children}</div>;
};

export default NumberBadge
