import React from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';

const StyledButton = styled(Button)({
  textTransform: 'none',
});

// eslint-disable-next-line react/prop-types
export default function FleetButton({ children, ...props }) {
  return <StyledButton {...props}>{children}</StyledButton>;
}
