export const initialState = {
  isLoadingVehicles: true,
  vehicles: [],
  registrationNumberFilter: '',
  filteredVehicles: [],
  vehicle: null,
  isVehicleDialogOpen: false,
};

export const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_IS_LOADING_VEHICLES':
      return { ...state, isLoadingVehicles: action.payload };
    case 'SET_VEHICLES':
      return { ...state, vehicles: action.payload };
    case 'SET_REGISTRATION_NUMBER_FILTER':
      return {
        ...state,
        registrationNumberFilter: action.payload,
      };
    case 'SET_FILTERED_VEHICLES':
      return {
        ...state,
        filteredVehicles: action.payload,
      };
    case 'SET_VEHICLE':
      return {
        ...state,
        vehicle: action.payload,
      };
    case 'SET_IS_VEHICLE_DIALOG_OPEN':
      return {
        ...state,
        isVehicleDialogOpen: action.payload,
      };
    default:
      return state;
  }
};
