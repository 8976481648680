import React, { useEffect, useState } from 'react';
import { useCompany } from '../hooks/company';
import { useRepository } from '../hooks/api';
import { initialState, reducer } from '../contexts/vignettesReducer';
import {
  VignettesStateProvider,
  // useVignettesStateValue,
} from '../contexts/vignettesStateContext';
import VignettesTable from '../views/vignettes/VignettesTable/VignettesTable';
import Loader from '../components/Loader';

export default function FleetStatus() {
  return (
    <VignettesStateProvider initialState={initialState} reducer={reducer}>
      <StatefulVignettes />
    </VignettesStateProvider>
  );
}

function StatefulVignettes() {
  const company = useCompany();
  const repository = useRepository();

  // TODO: Move this logic to the vignettesReducer
  const [vignettes, setVignettes] = useState([]);
  const [isLoadingData, setIsLoadingData] = useState(true);

  const init = async () => {
    const vignettes = await repository.company.getVignettes(
      company.taxIdentificationHash
    );
    setVignettes(vignettes);
    setIsLoadingData(false);
  };

  useEffect(() => {
    init();
  }, []);

  const handleVignettesRefresh = async () => {
    setIsLoadingData(true);
    await init();
  };

  return isLoadingData
    ? <Loader />
    : <VignettesTable
      vignettes={vignettes}
      onVignettesRefresh={handleVignettesRefresh}
    />;
}
