/* eslint-disable react/prop-types */
import * as React from 'react';

const ImagesContext = React.createContext({
  images: undefined,
});

export const ImagesProvider = ({ children, images }) => {
  const imagesRef = React.useRef(images);

  return (
    <ImagesContext.Provider value={{ images: imagesRef.current }}>
      {children}
    </ImagesContext.Provider>
  );
};

export const useImages = () => {
  const { images } = React.useContext(ImagesContext);

  if (!images) {
    throw new Error('Images must be defined');
  }

  return images;
};
