/* eslint-disable react/prop-types */
import React, { useMemo } from 'react';
import FleetTooltip from './FleetTooltip';
import { useDevice } from '../hooks/device';
import { getExpirationLabel } from '../shared/labels';
import { getCellStyleForMobile } from '../shared/styles';
import WatchSvg from '../shared/svgIcons/WatchSvg';

export default function VehicleStatus({ vignettes }) {
  const { isBrowser } = useDevice();

  const [chipStyle, textStyle, Chip] = useMemo(() => {
    if (vignettes.length === 0) {
      return [
        { backgroundColor: '#E8E8E8' },
        { color: '#636363' },
        () => (
          <FleetTooltip title='Pojazd nie ma aktywnych winiet'>
            <div>brak</div>
          </FleetTooltip>
        ),
      ];
    }

    const allVignettesHasNotStartedYet = vignettes.every(
      ({ hasStarted }) => hasStarted === false
    );
    if (allVignettesHasNotStartedYet) {
      return [
        { backgroundColor: '#E4F2E6' },
        { color: '#636363' },
        () => (
          <FleetTooltip title='Dla tego pojazdu winiety nie rozpoczęły jeszcze swojej ważności'>
            <div>
              <WatchSvg />
            </div>
          </FleetTooltip>
        ),
      ];
    }

    const fastestExpiringVignette = vignettes
      .filter(({ hasStarted }) => hasStarted === true)
      .reduce(
        (acc, vignette) => {
          if (vignette.endsIn < acc.endsIn) {
            return vignette;
          }

          return acc;
        },
        { endsIn: Number.POSITIVE_INFINITY }
      );

    if (fastestExpiringVignette.endsIn >= 7) {
      return [
        { backgroundColor: '#DFF1FF' },
        { color: '#046EB9' },
        () => (
          <FleetTooltip title='Najszybciej wygasająca winieta z wszystkich aktywnych winiet dla tego pojazdu'>
            <div>{getExpirationLabel(fastestExpiringVignette.endsIn)}</div>
          </FleetTooltip>
        ),
      ];
    }

    return [
      { backgroundColor: '#FFF59D' },
      { color: '#EF6C00' },
      () => (
        <FleetTooltip title='Najszybciej wygasająca winieta z wszystkich aktywnych winiet dla tego pojazdu'>
          <div>{getExpirationLabel(fastestExpiringVignette.endsIn)}</div>
        </FleetTooltip>
      ),
    ];
  }, [vignettes]);

  const getCellStyle = (style = {}) => ({
    ...getCellStyleForMobile(isBrowser),
    ...style,
  });

  return (
    <div style={{ display: 'flex' }}>
      <div
        style={{
          ...chipStyle,
          display: 'flex',
          flexDirection: 'row',
          height: '30px',
          lineHeight: '30px',
          borderRadius: '3px',
        }}
      >
        <div
          style={{
            ...(isBrowser
              ? { paddingLeft: '10px', paddingRight: '10px' }
              : { paddingLeft: '3px', paddingRight: '3px' }),
          }}
        >
          <div
            style={{
              ...textStyle,
              fontSize: 14,
              fontWeight: 500,
              ...getCellStyle(),
            }}
          >
            <Chip />
          </div>
        </div>
      </div>
    </div>
  );
}
