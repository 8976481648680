/* eslint-disable react/prop-types */
import * as React from 'react';

const CompanyContext = React.createContext(null);

export const CompanyProvider = ({ children, company }) => {
  const companyRef = React.useRef(company);
  return (
    <CompanyContext.Provider value={companyRef.current}>
      {children}
    </CompanyContext.Provider>
  );
};

export const useCompany = () => {
  const company = React.useContext(CompanyContext);

  if (!company) {
    throw new Error('A company must be defined');
  }

  return company;
};
