/* eslint-disable react/prop-types */
import React from 'react';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { lastRowWithoutBottomBorder } from '../../../shared/styles';

export default function CartRowEmpty() {
  return (
    <TableRow sx={{ ...lastRowWithoutBottomBorder }}>
      <TableCell colSpan={5}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            fontStyle: 'italic',
          }}
        >
          Nie znaleziono winiet w koszyku
        </div>
      </TableCell>
    </TableRow>
  );
}
