export const generateRedirectQuery = (company, user, extensions) => {
  const fleetDetails = {
    name: 'FLEET_DETAILS',
    persistence: 'KEEP_FOREVER',
    data: {
      taxIdentificationNumber: company.taxIdentificationNumber,
      email: null,
      mobile: null,
    },
  };

  if (user) {
    fleetDetails.data.email = user.email;
    fleetDetails.data.mobile = user.mobile;
  }

  const fleetData = [fleetDetails];

  if (extensions && extensions.length > 0) {
    fleetData.push({
      name: 'EXTENSIONS_DETAILS',
      persistence: 'DELETE_ON_FINISH',
      data: extensions,
    });
  }

  const fleetDataRaw = encodeURIComponent(JSON.stringify(fleetData));

  return `partner=flotomat&source=${company.taxIdentificationNumber}&fleet-data=${fleetDataRaw}`;
};
