/* eslint-disable react/prop-types */
import React, { useCallback } from 'react';
import List from '@mui/material/List';
import Card from '@mui/material/Card';
import Table from '@mui/material/Table';
import Alert from '@mui/material/Alert';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import Typography from '@mui/material/Typography';
import TableContainer from '@mui/material/TableContainer';
import { useDevice } from '../../../hooks/device';
import TableBox from '../../../components/TableBox';
import ViewHeader from '../../../components/ViewHeader';
import { getDateLabel } from '../../../shared/labels';
import { displayPrice } from '../../../shared/numbers';
import {
  getCellStyleForMobile,
  lastRowWithoutBottomBorder,
} from '../../../shared/styles';

export default function OrdersTable({ orders }) {
  const { isBrowser } = useDevice();

  const getCellStyle = useCallback(
    (style = {}) => ({
      ...getCellStyleForMobile(isBrowser),
      ...style,
    }),
    []
  );

  const getOrderStatusLabel = (status) => {
    switch (status) {
      case 'NEW':
        return 'nowe';
      case 'PENDING':
        return 'czeka na płatność';
      case 'CANCELED':
        return 'anulowane';
      case 'COMPLETED':
        return 'opłacone';
      case 'PLACED':
        return 'czeka na realizację';
      case 'REALIZED':
        return 'zrealizowane';
      case 'NOT_REALIZED':
        return 'niezrealizowane';
      default:
        return 'nieznany';
    }
  };

  const renderTableBody = () => {
    if (orders.length) {
      return orders.map((order) => (
        <TableRow key={order.id} sx={{ ...lastRowWithoutBottomBorder }}>
          <TableCell style={getCellStyle()}>F-{order.id}</TableCell>
          <TableCell style={getCellStyle()}>
            {getDateLabel(order.createdAt)}{' '}
            {new Date(order.createdAt).toLocaleTimeString()}
          </TableCell>
          <TableCell style={getCellStyle()}>
            {displayPrice(order.discountedValue)}
          </TableCell>
          <TableCell style={getCellStyle()}>
            {getOrderStatusLabel(order.status)}
          </TableCell>
        </TableRow>
      ));
    }

    return (
      <TableRow sx={{ ...lastRowWithoutBottomBorder }}>
        <TableCell
          colSpan={4}
          style={{
            textAlign: 'center',
            fontStyle: 'italic',
          }}
        >
          Nie znaleziono zamówień
        </TableCell>
      </TableRow>
    );
  };

  return (
    <List style={{ flexGrow: '1', padding: 0 }}>
      <ViewHeader>
        <Typography variant='h6' style={{ fontSize: 16 }}>
          Zamówienia
        </Typography>
      </ViewHeader>
      <Alert severity='info'>
        W tej sekcji znajdują się wszystkie zamówienia złożone za pośrednictwem
        serwisu Flotomat.pl
      </Alert>
      <Card variant='outlined' sx={{ mt: 1, mb: 1 }}>
        <TableBox>
          <TableContainer>
            <Table style={{ tableLayout: 'fixed' }}>
              <TableHead>
                <TableRow>
                  {isBrowser ? (
                    <>
                      <TableCell>Numer zamówienia</TableCell>
                      <TableCell>Data utworzenia</TableCell>
                      <TableCell>Kwota brutto</TableCell>
                    </>
                  ) : (
                    <>
                      <TableCell>Numer</TableCell>
                      <TableCell>Data</TableCell>
                      <TableCell>Brutto</TableCell>
                    </>
                  )}
                  <TableCell>Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>{renderTableBody()}</TableBody>
            </Table>
          </TableContainer>
        </TableBox>
      </Card>
    </List>
  );
}
