import * as React from 'react';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';

const UnexpectedError = () => {
  return (
    <Box
      style={{
        flexGrow: '1',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Card sx={{ maxWidth: '767px', padding: 3 }}>
        <CardContent>
          <Typography
            variant='h6'
            sx={{
              fontSize: 20,
              textAlign: 'center',
              fontWeight: 700,
              color: '#046eb9',
            }}
          >
            Flotomat.pl
          </Typography>
          <Typography
            sx={{
              fontSize: 14,
              fontWeight: 400,
              textAlign: 'center',
            }}
          >
            Wystąpił nieoczekiwany błąd
          </Typography>
        </CardContent>
        <CardContent>
          <Typography
            sx={{
              fontSize: 14,
              fontWeight: 400,
              textAlign: 'center',
            }}
          >
            Spróbuj ponownie za chwilę. Jeśli problem będzie się powtarzał,
            skontaktuj się z naszym działem obsługi klienta.
          </Typography>
        </CardContent>
      </Card>
    </Box>
  );
};

export default UnexpectedError;
