import React from 'react';
import propTypes from 'prop-types';
import './Flag.scss';
import FleetTooltip from '../FleetTooltip';

const TITLES = {
  AT: 'Austria',
  CH: 'Szwajcaria',
  BG: 'Bułgaria',
  CZ: 'Czechy',
  HU: 'Węgry',
  SK: 'Słowacja',
  SI: 'Słowenia',
  RO: 'Rumunia',
};

function Flag({ style = {}, onClick = () => {}, countryCode }) {
  return (
    <FleetTooltip title={TITLES[countryCode]}>
      <div
        style={style}
        className={`Flags Flags__${countryCode}`}
        onClick={onClick}
      />
    </FleetTooltip>
  );
}

Flag.propTypes = {
  style: propTypes.shape({}),
  onClick: propTypes.func,
  countryCode: propTypes.string.isRequired,
};

export default Flag;
