/* eslint-disable react/prop-types */
import React, { createContext, useContext, useReducer } from 'react';

const VignettesContext = createContext();

export const VignettesStateProvider = ({ reducer, initialState, children }) => (
  <VignettesContext.Provider value={useReducer(reducer, initialState)}>
    {children}
  </VignettesContext.Provider>
);

export const useVignettesStateValue = () => useContext(VignettesContext);
