import React, { useEffect, useState } from 'react';
import { useCompany } from '../hooks/company';
import { useRepository } from '../hooks/api';
import Loader from '../components/Loader';
import InvoicesTable from '../views/invoices/InvoicesTable/InvoicesTable';

export default function Invoices() {
  const company = useCompany();
  const repository = useRepository();

  const [invoices, setInvoices] = useState([]);
  const [isLoadingData, setIsLoadingData] = useState(true);

  const init = async () => {
    const invoices = await repository.company.getInvoices(
      company.taxIdentificationHash
    );
    setInvoices(invoices);
    setIsLoadingData(false);
  };

  useEffect(() => {
    init();
  }, []);

  return isLoadingData ? <Loader /> : <InvoicesTable invoices={invoices} />;
}
